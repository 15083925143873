.salespark-cssreset html {
  color: #000;
  background: #fff;
}
.salespark-cssreset body,
.salespark-cssreset div,
.salespark-cssreset dl,
.salespark-cssreset dt,
.salespark-cssreset dd,
.salespark-cssreset ul,
.salespark-cssreset ol,
.salespark-cssreset li,
.salespark-cssreset h1,
.salespark-cssreset h2,
.salespark-cssreset h3,
.salespark-cssreset h4,
.salespark-cssreset h5,
.salespark-cssreset h6,
.salespark-cssreset pre,
.salespark-cssreset code,
.salespark-cssreset form,
.salespark-cssreset fieldset,
.salespark-cssreset legend,
.salespark-cssreset input,
.salespark-cssreset textarea,
.salespark-cssreset p,
.salespark-cssreset blockquote,
.salespark-cssreset th,
.salespark-cssreset td {
  margin: 0;
  padding: 0;
  color: black;
}
.salespark-cssreset table {
  border-collapse: collapse;
  border-spacing: 0;
}
.salespark-cssreset fieldset,
.salespark-cssreset img {
  border: 0;
}
.salespark-cssreset address,
.salespark-cssreset caption,
.salespark-cssreset cite,
.salespark-cssreset code,
.salespark-cssreset dfn,
.salespark-cssreset em,
.salespark-cssreset strong,
.salespark-cssreset th,
.salespark-cssreset var {
  font-style: normal;
  font-weight: normal;
}
.salespark-cssreset ol,
.salespark-cssreset ul {
  list-style: none;
}
.salespark-cssreset caption,
.salespark-cssreset th {
  text-align: left;
}
.salespark-cssreset h1,
.salespark-cssreset h2,
.salespark-cssreset h3,
.salespark-cssreset h4,
.salespark-cssreset h5,
.salespark-cssreset h6 {
  font-size: 100%;
  font-weight: normal;
}
.salespark-cssreset q:before,
.salespark-cssreset q:after {
  content: "";
}
.salespark-cssreset abbr,
.salespark-cssreset acronym {
  border: 0;
  font-variant: normal;
}
.salespark-cssreset sup {
  vertical-align: text-top;
}
.salespark-cssreset sub {
  vertical-align: text-bottom;
}
.salespark-cssreset input,
.salespark-cssreset textarea,
.salespark-cssreset select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.salespark-cssreset input,
.salespark-cssreset textarea,
.salespark-cssreset select {
  font-size: 100%;
}
.salespark-cssreset legend {
  color: #000;
}

.salespark-cssreset ul {
  list-style-type: disc;
  list-style-position: inside;
}
.salespark-cssreset ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.salespark-cssreset ul ul,
.salespark-cssreset ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
.salespark-cssreset ol ol,
.salespark-cssreset ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
#yui3-css-stamp.cssreset-context {
  display: none;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-tabs-tab {
  margin: 0px 0px 8px !important;
}
.fade-in {
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  opacity: 1;
}
.fade-in-2 {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  opacity: 1;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
.white-link {
  color: #fff;
}
.white-link:hover {
  color: #e0eaff;
}
.btn-smaller {
  width: 32px !important;
  height: 32px !important;
  padding: 6.5px 0;
  font-size: 14px;
  border-radius: 0.625rem;
}

.entry-statistic-oldprice {
  text-decoration: line-through;
  color: #919191;
  font-size: 75%;
}
.update-notification-popup {
  position: fixed;
  bottom: 24px;
  left: 24px !important;
  box-shadow: 0px 0px 20px 6px rgba(184, 184, 184, 0.75);
  border-radius: 6px;
  background-color: #fff;
  padding: 18px;
  display: flex;
  max-width: calc(100vw - 68px);
  width: 300px;
  z-index: 9999;
}
.update-notification-popup button {
  background-color: #2977f6;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 0 16px;
  font-size: 0.9rem;
  height: 34px;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}
.code-box-demo {
  padding: 20px 24px 24px !important;
  border-bottom: 1px solid #e6ebf1;
}
@-webkit-keyframes msPulseEffect {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
@keyframes msPulseEffect {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
.inputGlow {
  min-width: 80px;
  margin-left: 5px;
  margin-right: 5px;
}
.inputGlow input,
.inputGlowSecondary input {
  text-align: center;
}
.inputGlowSecondary {
  width: 80px;
  max-width: 80px;
  text-align: center;
}
.inputGlow:hover,
.inputGlow:focus {
  -webkit-animation: msPulseEffect 1s infinite;
  animation: msPulseEffect 1s infinite;
}
.magicDateHelperList {
  padding-right: 15px;
  color: #3e79f7;
}
.magicDateInfoError {
  font-weight: 600;
  color: #ad0d15;
}
.magicDateInfoSuccess {
  font-weight: 600;
  color: #0d8e15;
}
.row-day {
  background-color: #e2e2e2;
}
#table-bank-transactions .ant-table-tbody > tr.ant-table-row:hover > td,
#table-bank-movements .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}

.table-row-yellow {
  background-color: #fff7c7 !important;
}
.table-row-green {
  background-color: #ddffdd !important;
}
.table-row-blue {
  background-color: rgb(221, 226, 255);
}
.table-row-green:hover,
.table-row-green:hover > td {
  background-color: #e2ffe2 !important;
}
.table-row-red {
  background-color: #ffffff !important;
}
.table-row-red:hover,
.table-row-red:hover > td {
  background-color: #fff8f8 !important;
}
.table-row-yellow:hover,
.table-row-yellow:hover > td {
  background-color: #ffffe0 !important;
}
.ql-snow * {
  color: #000;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-bottom: 0px !important;
  border-radius: 6px 6px 0px 0px !important;
}
.ql-editor {
  min-height: 50vh;
  overflow: visible !important;
}
.ql-container {
  border-radius: 0px 0px 6px 6px !important;
}
.ql-tooltip {
  left: 0 !important;
}

.ql-insertCustomTags {
  width: 280px !important;
}
.ql-picker.ql-insertCustomTags {
  width: 90px;
}
.ql-insertCustomTags .ql-picker-label:before {
  content: attr(data-label);
}

.ql-insertCustomTags .ql-picker-item {
  font-size: 11px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.ql-insertCustomTags .ql-picker-item:before {
  content: attr(data-label);
}

.roundedTag {
  font-size: 10px;
  padding: 0px 6px;
  margin: 0px 5px 5px 0px;
  line-height: 20px;
  border-radius: 10px;
}
.small-button,
.small-button .ant-btn-icon-only {
  height: 26px !important;
  width: 26px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  border-radius: 6px !important;
}
.table-small .ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 8px !important;
}

.table-small .ant-table-tbody > tr.ant-table-row:hover > td,
.table-small .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: rgb(210 249 255) !important;
}

.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding-left: 4px !important;
  padding-right: 8px !important;
}
.ant-table tfoot > tr > td:first-child,
.ant-table tfoot > tr > th:first-child,
.ant-table-tbody > tr > td:first-child,
.ant-table-thead > tr > th:first-child {
  padding-left: 16px !important;
  padding-right: 8px !important;
}
.ant-table tfoot > tr > td:last-child,
.ant-table tfoot > tr > th:last-child,
.ant-table-tbody > tr > td:last-child,
.ant-table-thead > tr > th:last-child {
  padding-left: 4px !important;
  padding-right: 16px !important;
}

.small-table .ant-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.small-table .ant-table-tbody > tr > td,
.small-table .ant-table-thead > tr > th {
  padding-left: 2px !important;
  padding-right: 4px !important;
}
.small-table .ant-table tfoot > tr > td:first-child,
.small-table .ant-table tfoot > tr > th:first-child,
.small-table .ant-table-tbody > tr > td:first-child,
.small-table .ant-table-thead > tr > th:first-child {
  padding-left: 8px !important;
  padding-right: 4px !important;
}

.card-fit-content {
  width: fit-content;
}

.clearfix {
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.full-button {
  display: contents;
}
.full-button button {
  height: 100% !important;
  min-height: 40px !important;
}

.full-button2 {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  /* display: contents; */
}
.full-button2 button {
  height: 40px !important;
}
.service-card {
  width: 100% !important;
  font-size: 16px !important;
}

.service-card .top-tag {
  font-size: 11px !important;
  padding: 8px 5px !important;
}

.tag-s {
  font-size: 11px !important;
  padding: 4px !important;
  margin: 2px !important;
  line-height: 12px !important;
}

.service-card .top-tag h4 {
  font-size: 18px !important;
  padding: 8px 5px !important;
}

.services-table .ant-table-tbody > tr > td {
  padding: 15px;
  padding-left: 2px;
  padding-right: 2px;
}

.service-card-small {
  border-bottom-width: 0px !important;
  margin: 0 0 0px !important;
  border-radius: 0px !important;

  font-size: 15px !important;
  width: 100%;
  border-width: 0px !important;
}
.service-card-small .ant-tag {
  font-weight: 600;
  font-size: 11px !important;
  padding: 4px 5px !important;
}

.service-card-small .ant-tag h4 {
  font-weight: 600;
  font-size: 15px !important;
  padding: 4px 5px !important;
}

.service-card-danger {
  border-width: 2px !important;
  border-color: #ff4d4f !important;
  width: 100%;
}
.service-is-forecast {
  border-width: 2px !important;
  border-color: #9b0c9b !important;
  border-style: dashed !important;
  width: 100%;
}

.service-card {
  /* border-bottom-width: 0px !important; */
  margin: 0 0 0 0px !important;
  border-radius: 0px !important;
  margin-bottom: 6px !important;
}
.service-card .ant-card-body {
  padding: 5px !important;
}

.services-list span:last-of-type .service-card {
  border-bottom-width: 2px !important;
}

.export-preview-drawer .ant-drawer-content-wrapper .ant-drawer-body {
  padding: 0px !important;
}

.pax-actions-tag {
  margin-right: 2px;
  width: 40px;
}
.service-card-actions {
  margin-left: 20px;
}
.app-content:has(.page-pier-dashboard) {
  padding: 4px !important;
}

.smallTag {
  font-size: 10px !important;
  padding: 0px 5px !important;
  margin: 0px 5px 0px 8px !important;
  line-height: 20px !important;
}

.smallTag span.anticon {
  font-size: 12px;
}

.smallTag2 {
  font-size: 10px !important;
  padding: 0px 5px !important;
  margin: 0px 3px 0px 0px !important;
  line-height: 18px !important;
}

.smallTag2 span.anticon {
  font-size: 12px;
}

.smallTagNoMargin {
  font-size: 10px;
  padding: 0px 5px;
  margin: 0px 2px 0px 2px;
  line-height: 20px;
}

.shadow-affix-card {
  -webkit-box-shadow: 0px 7px 12px -7px rgba(64, 64, 64, 1);
  -moz-box-shadow: 0px 7px 12px -7px rgba(64, 64, 64, 1);
  box-shadow: 0px 7px 12px -7px rgba(64, 64, 64, 1);
  margin-left: -3px;
  margin-right: -5px;
}
.ant-affix {
  z-index: 2 !important;
}
.checkin-drawer .ant-drawer-body {
  padding: 8px !important;
}

.entry-total-from-item .ant-form-item-label {
  max-width: 150px;
  flex: none;
}

.occupation-item-forecast {
  font-size: 11px;
  line-height: 11px;
}

.occupation-item-product {
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
}
.add-passenger-form .ant-form-item {
  margin-bottom: 8px;
}

.split-countries {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.split-countries-header {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.split-countries .split-countries-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.split-countries .split-countries-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
}

.split-countries .split-countries-pax {
  font-size: 17px;
  font-weight: 600;
}
.split-countries .split-countries-new-pax {
  font-size: 18px;
  font-weight: 600;
}

.team-occupation-map-card {
  font-size: 75%;
}

.team-occupation-map-card .occupation-item-product {
  font-size: 11px;
}

.team-occupation-map-card .item-pax-info {
  font-size: 9px;
}

.phoneOtpContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.phoneOtpWaitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.phoneOtpContainerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.phoneOtpContainerInput {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.phoneOtpContainerCountdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.phoneOtp input {
  width: 4rem !important;
  height: 4rem !important;
  margin: 0 1rem !important;
  font-size: 3rem !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.phoneOtpContainerCountdown .timer-wrapper {
  display: flex;
  justify-content: center;
}
.phoneOtpContainerCountdown .timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.phoneOtpContainerCountdown .text {
  color: #aaa;
}
.phoneOtpContainerCountdown .value {
  font-size: 40px;
}
.phoneOtpContainerCountdown .info {
  max-width: 260px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
.occupation-affix .ant-affix {
  top: 0 !important;
}
.entry-statistic-price-small {
  font-size: 50%;
  color: #363636;
}
.deleted-item {
  color: #ff6b72;
}
